import React from 'react'
import { useState, useEffect } from 'react'
import iconMapWhite from '../assets/images/icon-map-white.svg'
import iconMap from '../assets/images/icon-map.svg'
import iconRotate from '../assets/images/icon-rotate.svg'
import searchImage from '../assets/images/icon-search.svg'
import { useTranslation } from 'react-i18next';
import MapWithMarkers from './MapWithMarkers'

export default function PaymentPoints() {
  const { t } = useTranslation();
  const placeTable = document.querySelector('.results-area .results-list');
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedUserDistrict, setSelectedUserDistrict] = useState("");
  const [selectedSectorResults, setSelectedSectorResults] = useState([]);
  const [searchTerm, setSearchTermHandler] = useState("");
  const [userLocation, setUserLocation] = useState({});
  const noResults = document.querySelector('.no-results');
  let selectedSector = localStorage.getItem('sector');
  const [nearestLocations,listNearestLocations] = useState([]);;

  function setSearchTerm(e) {
    setSearchTermHandler(e.target.value);
  }

  function setCity(e) {
    document.querySelector('.search-area input').value = "";
    setSelectedCity(e.target.value);
    placeTable.innerHTML = `<div class='text-center py-4 no-results'>${t('no-results')}</div>`;
  }
  function setDistrict(e) {
    setSelectedDistrict(e.target.value);
  }
  function setFavDistrict(e) {
    e.preventDefault();
    setSelectedCity(e.target.dataset.city);
    setSelectedDistrict(e.target.dataset.district);
  }

  const [cities, getCities] = useState([]);
  const [districts, getDistricts] = useState([]);

  function callCities() {
    fetch("/cities.json")
      .then((res) => res.json())
      .then((data) => {
        getCities(data);
    });
  }

  function callDistricts() {
    fetch("/cities.json")
      .then((res) => res.json())
      .then((data) => {
        data.forEach(e => {
          if (selectedCity === e.name) {
            getDistricts(e.districts)
          }
        });
    });
  }

  // Result Template
  function ResultTemplate(props) {
    return (
      <div class="d-center flex-wrap w-100">
        <span class="col-8 col-lg-2 px-lg-3">{props.name}</span>
        <span class='col-12 col-lg-4 order-last order-lg-0 pe-3 pt-3 pt-lg-0 fs-12'>{props.address}</span>
        <span class='col-2 d-none d-lg-inline'>{props.city}</span>
        <span class='col-2 d-none d-lg-inline'>{props.district}</span>
        <span class="col-4 col-lg-2 text-center"><a href="https://www.google.com/maps/search/lat,lng" target="_blank" rel="noopener noreferrer"><img src={iconMap} alt="icon" /></a></span>
      </div>
    )
  }

  function setPopularCities() {
    const defaultCity = document.querySelector('#city option[value="select"]');
    const allCities = document.querySelectorAll('#city option');
    const popularCities = ["17","33","48","6","35","34","7"];
    allCities.forEach(each => {
      if (popularCities.includes(each.dataset.number)) {
        // defaultCity.after(each);
        defaultCity.insertAdjacentElement('afterend', each)
      }
    }); 
  }

  useEffect(() => {
    callCities();
  },[]);

  useEffect(() => {
    callDistricts();
  },[selectedCity]);


  useEffect(() => {
      if (selectedSector !== "") {
          fetch(`/merchant-list-${selectedSector}.json`)
          .then((res) => res.json())
          .then((data) => {
            setSelectedSectorResults(data);
          });
      }
  },[selectedSector]);

  useEffect(() => {
    listNearestLocations([]);
    document.querySelector('.results-area .results-list').innerHTML = "";
    let totalAmount = 0;
    const locationUser = { lat: userLocation.userLat, lng: userLocation.userLng };
    selectedSectorResults.filter((each) => each.City === selectedCity && each.District === selectedDistrict).map(item => {
      
      // Calculate Distance
      
      let locationPoint = { lat: parseFloat(item.Lat.replace(',','.')), lng: parseFloat(item.Lng.replace(',','.')) };
      function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }

      function calculateDistance(coord1, coord2) {
        let R = 6371; // Radius of the Earth in kilometers
        let dLat = deg2rad(coord2.lat - coord1.lat);
        let dLng = deg2rad(coord2.lng - coord1.lng);

        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let distance = R * c; // Distance in kilometers

        return distance;
      }

      let showDistance = calculateDistance(locationUser, locationPoint);

      let place = document.createElement('div');
      place.classList.add('d-flex','flex-wrap','d-lg-center','py-2');
      place.setAttribute('style',`order: ${showDistance.toFixed(2).replace('.','')}`);
      place.innerHTML = `
          <span class="col-8 col-lg-2 px-lg-3">${item.SignboardName}</span>
          <span class='col-12 col-lg-4 order-last order-lg-0 pe-3 pt-3 pt-lg-0 fs-12'>${item.SaleAddress}</span>
          <span class='col-2 d-none d-lg-inline'>${item.City}</span>
          <span class='col-2 d-none d-lg-inline'>${item.District}</span>
          <span class="col-4 col-lg-2 text-center"><a href="https://www.google.com/maps/search/${item.Lat.replace(',','.')},${item.Lng.replace(',','.')}" target="_blank"><img src=${iconMap} alt="icon" />${locationUser.lat !== undefined && selectedDistrict === selectedUserDistrict ? "<p class='mt-2 mb-0 text-dark'>" + showDistance.toFixed(2) + " km</p>" : ""}</a></span>
        
        `;
      placeTable.append(place);
      totalAmount ++;
      
      // Update Nearest Locations Array

      const newPlaces = [];
      let addPlace = {
				name: item.SignboardName,
				city: item.City,
				district: item.District,
        address: item.SaleAddress,
				lat: Number(item.Lat.replace(",", ".")),
				lng: Number(item.Lng.replace(",", ".")),
				directions: `<a href="https://www.google.com/maps/search/${item.Lat.replace(",",".")},${item.Lng.replace(",", ".")}" target="_blank">${t('get-directions')}</a>`,
			};      
      newPlaces.push(addPlace);
      listNearestLocations((prevPlaces) => [...prevPlaces, ...newPlaces]);
      
    });
    if (totalAmount === 0) {
      // document.querySelector('.search-area').classList.add('d-none');
      document.querySelector('.results-area .results-list').innerHTML = `<div class='text-center py-4 no-results'>${t('no-results')}</div>`
    } else {
      // document.querySelector('.search-area').classList.remove('d-none');
    }
	}, [selectedDistrict]);

  useEffect(() => {
      document.querySelector('.results-area .results-list').innerHTML = "";
      const locationUser = { lat: userLocation.userLat, lng: userLocation.userLng };
      let totalAmount = 0;
      
      if (selectedCity && selectedDistrict !== "") {
        if (searchTerm.length > 2) {
          selectedSectorResults.filter((each) => (each.City === selectedCity && each.District === selectedDistrict) && (each.SignboardName.includes(searchTerm) || each.SignboardName.toLowerCase().includes(searchTerm.toLowerCase()) || each.SubSector.includes(searchTerm) || each.SubSector.toLowerCase().includes(searchTerm.toLowerCase())) ).map(item => {

          // Calculate Distance
      
          let locationPoint = { lat: parseFloat(item.Lat.replace(',','.')), lng: parseFloat(item.Lng.replace(',','.')) };
          function deg2rad(deg) {
            return deg * (Math.PI / 180);
          }

          function calculateDistance(coord1, coord2) {
            let R = 6371; // Radius of the Earth in kilometers
            let dLat = deg2rad(coord2.lat - coord1.lat);
            let dLng = deg2rad(coord2.lng - coord1.lng);

            let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            let distance = R * c; // Distance in kilometers

            return distance;
          }

          let showDistance = calculateDistance(locationUser, locationPoint);

          let place = document.createElement('div');
          place.classList.add('d-flex','flex-wrap','d-lg-center','py-2');
          place.setAttribute('style',`order: ${showDistance.toFixed(2).replace('.','')}`);
          place.innerHTML = `
            
              <span class="col-8 col-lg-2 px-lg-3">${item.SignboardName}</span>
              <span class='col-12 col-lg-4 order-last order-lg-0 pe-3 pt-3 pt-lg-0 fs-12'>${item.SaleAddress}</span>
              <span class='col-2 d-none d-lg-inline'>${item.City}</span>
              <span class='col-2 d-none d-lg-inline'>${item.District}</span>
              <span class="col-4 col-lg-2 text-center"><a href="https://www.google.com/maps/search/${item.Lat.replace(',','.')},${item.Lng.replace(',','.')}" target="_blank"><img src=${iconMap} alt="icon" />${locationUser.lat !== undefined && selectedDistrict === selectedUserDistrict ? "<p class='mt-2 mb-0 text-dark'>" + showDistance.toFixed(2) + " km</p>" : ""}</a></span>
            
          `;
          placeTable.append(place);
          totalAmount ++;
        });
        } else {
            selectedSectorResults.filter((each) => each.City === selectedCity && each.District === selectedDistrict).map(item => {

              // Calculate Distance
      
              let locationPoint = { lat: parseFloat(item.Lat.replace(',','.')), lng: parseFloat(item.Lng.replace(',','.')) };
              function deg2rad(deg) {
                return deg * (Math.PI / 180);
              }

              function calculateDistance(coord1, coord2) {
                let R = 6371; // Radius of the Earth in kilometers
                let dLat = deg2rad(coord2.lat - coord1.lat);
                let dLng = deg2rad(coord2.lng - coord1.lng);

                let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                let distance = R * c; // Distance in kilometers

                return distance;
              }

              let showDistance = calculateDistance(locationUser, locationPoint);

              let place = document.createElement('div');
              place.classList.add('d-flex','flex-wrap','d-lg-center','py-2');
              place.setAttribute('style',`order: ${showDistance.toFixed(2).replace('.','')}`);
              place.innerHTML = `

                  <span class="col-8 col-lg-2 px-lg-3">${item.SignboardName}</span>
                  <span class='col-12 col-lg-4 order-last order-lg-0 pe-3 pt-3 pt-lg-0 fs-12'>${item.SaleAddress}</span>
                  <span class='col-2 d-none d-lg-inline'>${item.City}</span>
                  <span class='col-2 d-none d-lg-inline'>${item.District}</span>
                  <span class="col-4 col-lg-2 text-center"><a href="https://www.google.com/maps/search/${item.Lat.replace(',','.')},${item.Lng.replace(',','.')}" target="_blank"><img src=${iconMap} alt="icon" />${locationUser.lat !== undefined && selectedDistrict === selectedUserDistrict ? "<p class='mt-2 mb-0 text-dark'>" + showDistance.toFixed(2) + " km</p>" : ""}</a></span>
              `;
              placeTable.append(place);
              totalAmount ++;
            });
        }
      } else {
        if (searchTerm.length > 2) {
            selectedSectorResults.filter((each) => each.SignboardName.includes(searchTerm) || each.SignboardName.toLowerCase().includes(searchTerm.toLowerCase()) || each.SubSector.includes(searchTerm) || each.SubSector.toLowerCase().includes(searchTerm.toLowerCase())).map(item => {

            // Calculate Distance
        
            let locationPoint = { lat: parseFloat(item.Lat.replace(',','.')), lng: parseFloat(item.Lng.replace(',','.')) };
            function deg2rad(deg) {
              return deg * (Math.PI / 180);
            }

            function calculateDistance(coord1, coord2) {
              let R = 6371; // Radius of the Earth in kilometers
              let dLat = deg2rad(coord2.lat - coord1.lat);
              let dLng = deg2rad(coord2.lng - coord1.lng);

              let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
              let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              let distance = R * c; // Distance in kilometers

              return distance;
            }

            let showDistance = calculateDistance(locationUser, locationPoint);
            let place = document.createElement('div');
            place.classList.add('d-flex','flex-wrap','d-lg-center','py-2');
            place.setAttribute('style',`order: ${showDistance.toFixed(2).replace('.','')}`);
            place.innerHTML = `
                <span class="col-8 col-lg-2 px-lg-3">${item.SignboardName}</span>
                <span class='col-12 col-lg-4 order-last order-lg-0 pe-3 pt-3 pt-lg-0 fs-12'>${item.SaleAddress}</span>
                <span class='col-2 d-none d-lg-inline'>${item.City}</span>
                <span class='col-2 d-none d-lg-inline'>${item.District}</span>
                <span class="col-4 col-lg-2 text-center"><a href="https://www.google.com/maps/search/${item.Lat.replace(',','.')},${item.Lng.replace(',','.')}" target="_blank"><img src=${iconMap} alt="icon" />${locationUser.lat !== undefined && selectedDistrict === selectedUserDistrict ? "<p class='mt-2 mb-0 text-dark'>" + showDistance.toFixed(2) + " km</p>" : ""}</a></span>
            `;
            placeTable.append(place);
            totalAmount ++;
          });
        }
      }

      if (totalAmount === 0) {
        document.querySelector('.results-area .results-list').innerHTML = `<div class='text-center py-4 no-results'>${t('no-results')}</div>`
      }
  },[searchTerm]);

  // Find Nearest Locations

  function setNearestLocation(lat,lng) {
      // Set User Location
      const setLocation = {
        userLat: lat,
        userLng: lng
      }
      setUserLocation(setLocation);
      
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_API_KEY}`;
      fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK') {
          const addressComponents = data.results[0].address_components;
          const getCity = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
          const getDistrict = addressComponents.find(component => component.types.includes('administrative_area_level_2'));
          setSelectedCity(getCity.long_name);
          setSelectedDistrict(getDistrict.long_name);
          setSelectedUserDistrict(getDistrict.long_name);
          
          setTimeout(() => {
            document.getElementById('city').value = getCity.long_name;
            document.getElementById('district').value = getDistrict.long_name;
          },300);
        } else {
          console.error('Error in geocoding request:', data.status);
        }
      })
      .catch(error => {
        console.error('Error fetching geocoding data:', error);
      });
  }

  function getUserLocation() {
    if (noResults) {
      noResults.innerHTML = `<img src="${iconRotate}" class="infinite-rotate" /> ${t('waiting-for-the-results')}`;
    } else {
      document.querySelector('.results-area .results-list').innerHTML = `<div class='text-center py-4 no-results'><img src="${iconRotate}" class="infinite-rotate" /> ${t('waiting-for-the-results')}</div>`;
    }
    navigator.geolocation.getCurrentPosition((position) => {
      let userLat = position.coords.latitude;
      let userLng = position.coords.longitude;
      setNearestLocation(userLat,userLng);
    });

    document.querySelector('.show-on-map').classList.remove('d-none');
  }

  return (
		<>
			<div className="container select-location px-0">
				<div className="row">
					<div className="col-12 col-lg-4">
						<select
							className="w-100 rounded-pill"
							name="city"
							id="city"
							onChange={setCity}
						>
							<option value="select">{t("select-city")}</option>
							{cities.map((city) => {
								return (
									<option key={city.id} value={city.name} data-number={city.id}>
										{city.name}
									</option>
								);
							})}
						</select>
					</div>
					<div className="col-12 col-lg-4 mt-3 mt-lg-0">
						<select
							className="w-100 rounded-pill"
							name="district"
							id="district"
							onChange={setDistrict}
						>
							<option value="select">{t("select-district")}</option>
							{districts.map((district) => {
								return (
									<option key={district} value={district}>
										{district}
									</option>
								);
							})}
						</select>
					</div>
					<div className="d-flex align-items-center flex-column flex-lg-row col-12 col-lg-4">
						<span className="me-0 me-lg-4 my-3 my-lg-0">{t("or")}</span>
						<button
							className="align-items-center btn d-flex find-nearest h-100 justify-content-center ms-auto rounded-pill text-white w-100"
							onClick={getUserLocation}
						>
							<img src={iconMapWhite} alt="icon" />
							<span className="ms-2">{t("find-nearest-locations")}</span>
						</button>
					</div>
				</div>
			</div>
			{/* prettier-ignore */}
			<div className="border favourite-places px-2 pt-3 pb-2 rounded-4 mt-4">
        {/* <button className="btn btn-fav mx-2 mb-2" data-city="İstanbul" data-district="Fatih" onClick={setFavDistrict}>#{t("Fatih")}</button> */}
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="İstanbul" data-district="Fatih" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Fatih")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("İstanbul")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="İstanbul" data-district="Beyoğlu" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Beyoğlu")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("İstanbul")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Kaş" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Kaş")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Kemer" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Kemer")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Kepez" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Kepez")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Alanya" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Alanya")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Konyaaltı" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Konyaaltı")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Antalya" data-district="Manavgat" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Manavgat")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Antalya")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Muğla" data-district="Bodrum" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Bodrum")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Muğla")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Muğla" data-district="Dalaman" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Dalaman")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Muğla")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Muğla" data-district="Datça" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Datça")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Muğla")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Muğla" data-district="Fethiye" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Fethiye")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Muğla")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Muğla" data-district="Marmaris" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Marmaris")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Muğla")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="Ankara" data-district="Çankaya" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Çankaya")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("Ankara")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="İzmir" data-district="Çeşme" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Çeşme")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("İzmir")}</span>
        </button>
        <button className="btn btn-fav mx-2 mb-2 d-inline-flex align-items-center" data-city="İzmir" data-district="Alaçatı" onClick={setFavDistrict}>
          <span className='district fs-14'>#{t("Alaçatı")}</span>
          <span className='city px-2 ms-2 rounded fs-10'>#{t("İzmir")}</span>
        </button>
      </div>
			<div className="results my-4 border-lg p-lg-4">
				<div className="ads-area px-0 my-4 d-none">
					<img src="" alt="img" className="w-100" />
				</div>
				<div className="results-area">
					<MapWithMarkers
						userLocation={userLocation}
						locations={nearestLocations}
					/>
					<div className="search-area w-100 mb-4 d-none">
						<img src={searchImage} alt="img" />
						<input
							type="text"
							placeholder={t("filter-results")}
							onInput={setSearchTerm}
						/>
					</div>
					<div className="d-center w-100 rounded-top-3 results-area-title">
						<span className="col-8 col-lg-2 px-3">{t("name")}</span>
						<span className="col-4 d-none d-lg-inline">{t("address")}</span>
						<span className="col-2 d-none d-lg-inline">{t("city")}</span>
						<span className="col-2 d-none d-lg-inline">{t("district")}</span>
						<span className="col-4 col-lg-2 text-center px-2">
							{t("location")}
						</span>
					</div>
					<div className="results-list">
						<p className="text-center py-4 no-results m-0">{t("no-results")}</p>
					</div>
				</div>
			</div>
		</>
	);
}