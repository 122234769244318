import React, {useEffect, useState} from "react";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import iconOnMap from "../assets/images/icon-on-map.svg";
import iconPinPng from "../assets/images/icon-map-assisty-filled-inside.png";
import {useTranslation} from "react-i18next";

export default function TurkeyMapWithMarkers({userLocation}) {
	const {t} = useTranslation();

	const places = [];
	async function getPlaces() {
		const response = await fetch("/merchant-list-restaurant.json");
		const getPlaces = await response.json();
		// const featuredCities = ["İstanbul", "İzmir", "Antalya", "Muğla"];

		getPlaces.map((place) => {
			let pin = {
				lat: Number(place.Lat.replace(",", ".")),
				lng: Number(place.Lng.replace(",", ".")),
				name: place.SignboardName,
				info: place.InvoiceTitle,
				city: place.City,
				district: place.District,
				address: place.SaleAddress,
				directions: `<a href="https://www.google.com/maps/search/${place.Lat.replace(
					",",
					"."
				)},${place.Lng.replace(",", ".")}" target="_blank">${t(
					"get-directions"
				)}</a>`,
			};
			places.push(pin);
		});
	}
	getPlaces();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}`;
		script.async = true;
		script.defer = true;
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
			delete window.loadMap;
		};
	}, [process.env.REACT_APP_API_KEY]);

	function showMap() {
		document.getElementById("tMap").classList.remove("d-none");
		initMap();
	}

	async function initMap() {
		// Request needed libraries.
		const {Map, InfoWindow} = await window.google.maps.importLibrary("maps");
		const {AdvancedMarkerElement, PinElement} = await window.google.maps.importLibrary(
			"marker"
		);
		const map = new window.google.maps.Map(document.getElementById("tMap"), {
			zoom: 6.5,
			center: {lat: 38.896234, lng: 35.371309},
			mapId: '5e3ec24e7704b61c',
		});
		const infoWindow = new window.google.maps.InfoWindow({
			content: "",
			disableAutoPan: true,
		});

		// Create an array of alphabetical characters used to label the markers.
		const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

		// Add some markers to the map.
		const markers = places.map((position, i) => {
			const customIcon = {
				url: iconPinPng,
				scaledSize: new window.google.maps.Size(36, 36), // size
				origin: new window.google.maps.Point(0, 0), // origin(top-left corner)
				anchor: new window.google.maps.Point(20, 40), // anchor point (center-bottom)
			};
			const marker = new window.google.maps.Marker({
				position,
				map: map,
				icon: customIcon,
			});

			// markers can only be keyboard focusable when they have click listeners
			// open info window when marker is clicked
			marker.addListener("click", () => {
				infoWindow.setContent(
					`<div><h5>${position.name}</h5><p>${position.city}/${position.district}</p><p>${position.address}</p>${position.directions}</div>`
				);
				infoWindow.open(map, marker);
			});
			return marker;
		});

		// Add a marker clusterer to manage the markers.
		new MarkerClusterer({markers, map});
	}

	return (
		<>
			<h3 id="all-locations" className="d-flex justify-content-center align-items-center text-white text-center fw-semibold my-4 mt-lg-0 mb-lg-5 pt-3">
				<span>{t("payment-points")}</span>
				<button className="btn btn-qr d-inline-block ms-3" onClick={showMap} data-bs-toggle="modal" data-bs-target="#tMapModal">
					<img src={iconOnMap} alt="icon" style={{height: "25px"}} />
					<span className="ms-2">{t("show-on-map")}</span>
				</button>
			</h3>

			<div className="modal fade" id="tMapModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div id="tMap" className="rounded-5 mb-5 d-none" style={{height: "680px", width: "100%"}}></div>
						</div>
					</div>
				</div>
			</div>
			<script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
		</>
	);
}
